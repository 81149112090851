import React from 'react';
import classNames from 'classnames';
import styles from './PicDesc.module.scss';

interface PicDescProps {
  title: string;
  description: string;
  position: string;
  pic: string;
}

export function PicDesc({ title, description, position, pic }: PicDescProps) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.reversed]: position === 'reversed',
      })}
    >
      <div className={styles.textContainer}>
        <h1 className={styles.title}>{title}</h1>
        <h3 className={styles.description}>{description}</h3>
      </div>

      <img src={pic} alt={title} />
    </div>
  );
}
