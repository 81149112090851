export const servImg = [
  require('./service_1.JPG'),
  require('./service_2.JPG'),
  require('./service_3.png'),
  require('./service_4.JPG'),
  require('./service_5.jpg'),
  require('./service_6.JPG'),
  require('./service_7.jpg'),
  require('./service_8.jpg'),
];

export const ServArr = [
  [
    { name: 'Full Arms Sweet Service', price: '$35' },
    { name: 'Full Legs Sweet Service', price: '$35' },
    { name: 'Under Arms Sweet Service', price: '$20' },
    { name: 'Stomach Sweet Service', price: '$20' },
    { name: 'Back Sweet Service', price: '$20' },
    { name: 'Full Body Sweet Service', price: '$80' },
  ],
  [
    { name: 'Brazilian Face Treatment', price: '$25' },
    { name: 'Brazilian Bikini Treatment', price: '$40' },
    { name: 'Brazilian Bikini Line Treatment', price: '$20' },
    { name: 'Brazilian Under Arms Treatment', price: '$10' },
    { name: 'Brazilian Full Body Treatment', price: '$100' },
  ],
  [
    { name: 'Swedish/Relaxation Massage', price: '$80' },
    { name: 'Therapeutic/Deep Tissue Massage', price: '$90' },
    { name: 'Hot Stone Massage', price: '$90' },
  ],
  [
    { name: 'Eyebrows Threading', price: '$5' },
    { name: 'Upper Lip Threading', price: '$5' },
    { name: 'Chin Threading', price: '$5' },
    { name: 'Full Face Threading', price: '$15' },
    { name: 'Eyebrows & Upper Lip & Chin Threading', price: '$10' },
  ],
  [
    { name: 'Under Arms Hot Waxing', price: '$10' },
    { name: 'Full Arms Hot Waxing', price: '$25' },
    { name: 'Full Legs Hot Waxing', price: '$25' },
    { name: 'Stomach Hot Waxing', price: '$10' },
    { name: 'Back Hot Waxing', price: '$10' },
    { name: 'Full Body Hot Waxing', price: '$70' },
  ],
  [
    { name: 'Classic Set Lashes', price: '$60' },
    { name: 'Volume Set Lashes', price: '$70' },
    { name: 'Mega Volume Set Lashes', price: '$90' },
  ],
  [
    { name: 'Manicure + Pedicure Nails', price: '$40' },
    { name: 'Gel Polish Nails', price: '$30' },
    { name: 'Colour Normal Nails', price: '$20' },
    { name: 'Manicure + Pedicure + Spa Nails', price: '$50' },
  ],
  [
    { name: 'Normal Skin Facial', price: '$40' },
    { name: 'Gold 24K Skin Facial', price: '$65' },
    { name: 'Hyaluronic Acid Whitening Facial', price: '$55' },
    { name: 'Facial Massage Skin Care', price: '$70' },
  ],
];

export const infoData = [
  {
    title: 'Massage',
    description:
      'Unwind with gentle strokes that melt stress away. Perfect for a peaceful, stress-free escape.',
  },
  {
    title: 'Hair removal',
    description:
      'Design your comfort and choose what works for your skin from Threading to Waxing to Sweet. Options: sweet, Brazilian, threading, hot waxing.',
  },
  {
    title: 'Skin',
    description:
      'Rejuvenate your skin with personalized care. Leaves your skin refreshed, glowing, and healthy.',
  },
  {
    title: 'Nails',
    description:
      'A treat for your hands and feet, offering expert care and attention to detail. Our technicians leave your nails looking flawless and your skin feeling soft.',
  },
  {
    title: 'Lashes',
    description: 'Choose your style, length, curviness, and color.',
  },
];
